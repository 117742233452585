import React from 'react';
import { SignUp } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';

const SignUpPage = () => {
    const location = useLocation();

    // Extract invitation token from URL parameters
    const params = new URLSearchParams(location.search);
    const invitationToken = params.get('__clerk_ticket');

    return (
        <div className="sign-up">
            {!invitationToken ? (
                <div className="error-message">
                    <p>Error: Missing invitation token. Please make sure you have a valid invitation link.</p>
                </div>
            ) : (
                <SignUp
                    forceRedirectUrl="/" // URL to redirect to after successful sign-up
                    initialValues={{
                        // Optionally pre-fill fields if needed
                        invitation_token: invitationToken
                    }}
                />
            )}
        </div>
    );
};

export default SignUpPage;