import React, { useState } from 'react';
import { BASE_URL } from '../constants';
import { Container, Paper, Title, TextInput, Button, Text, Box } from '@mantine/core';
import { useAuth } from '@clerk/clerk-react';
import { useCustomer } from './CustomerContext';
import { Navigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

const CreateUserPage = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { getToken } = useAuth();
    const {isAdmin} = useCustomer();

    if (!isAdmin) {
        showNotification({
            id: 'vip-access-denied',
            title: 'Access Denied',
            message: 'You do not have access to this page.',
            color: 'red',
            autoClose: 5000,
            position: 'top-right',
        });

        return <Navigate to="/" replace />;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = await getToken();
            const response = await fetch(`${BASE_URL}/invitation/inviteUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ email }),
            });

            // Handle response based on content type
            let result;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                result = await response.json();
            } else {
                result = await response.text();
            }

            if (response.ok) {
                setMessage('Invitation sent successfully!');
            } else {
                const errorMessage = result.message || 'Unknown error';
                setMessage('Failed to send invitation: ' + errorMessage);
            }
        } catch (error) {
            setMessage('An error occurred: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container size="sm" mt={20}>
            <Paper shadow="md" radius="lg" p="xl" style={{ backgroundColor: 'white' }}>
                <Title order={1} align="center" mb="md" style={{ fontWeight: 900 }}>
                    Create User
                </Title>
                <Box component="form" onSubmit={handleSubmit} style={{ maxWidth: 400, margin: '0 auto' }}>
                    <TextInput
                        label="Email"
                        placeholder="Enter your email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        mb="md"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        loading={loading}
                    >
                        Send Invitation
                    </Button>
                </Box>
                {message && (
                    <Text
                        align="center"
                        mt="md"
                        color={message.includes('successfully') ? 'green' : 'red'}
                    >
                        {message}
                    </Text>
                )}
            </Paper>
        </Container>
    );
};

export default CreateUserPage;
