import React, { useEffect, useState } from "react";
import {
  Container, Table, Title, Group, Paper, Text, ThemeIcon, Loader, Transition, Badge, ScrollArea, Stack, Flex
} from "@mantine/core";
import { IconClipboardList, IconUser, IconFileDescription, IconChevronRight } from "@tabler/icons-react";
import { useParams, useNavigate } from "react-router-dom";
import ButtonTo from "../pages/subpages/00-ButtonTo";
import { BASE_URL } from "../constants";
import { useMediaQuery } from '@mantine/hooks';
import ResetForm from "./ResetForm";
import { useCustomer } from "./CustomerContext";
import { useAuth } from "@clerk/clerk-react";

const ResponseDetailsPage = () => {
  const { userId } = useParams();
  const { isAdmin } = useCustomer();
  const [responses, setResponses] = useState([]);
  const [forms, setForms] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { getToken } = useAuth();

  const getRouteString = () => {
    return isAdmin ? 'report' : '';
  };

  const handleUserClick = (formId) => {
    navigate(`/responses/${userId}/${formId}`);
  };

  // const handleFormReset = (formId) => {
  //   setResponses((prevResponses) => prevResponses.filter(response => response.form !== formId));
  // };

  useEffect(() => {
    async function fetchUserResponse() {
      if (!userId) return;

      try {
        const token = await getToken();
        const response = await fetch(`${BASE_URL}/responses/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const formRes = await fetch(`${BASE_URL}/forms`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const customerRes = await fetch(`${BASE_URL}/customers/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.ok && formRes.ok && customerRes.ok) {
          const data = await response.json();
          const formData = await formRes.json();
          const customerData = await customerRes.json();

          const uniqueForms = data.reduce((acc, current) => {
            const isDuplicate = acc.find((response) => response.form === current.form);
            if (!isDuplicate) {
              acc.push(current);
            }
            return acc;
          }, []);

          setForms(formData);
          setResponses(uniqueForms);
          setCustomer(customerData);
        } else {
          throw new Error("Failed to fetch user responses");
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchUserResponse();
  }, [userId]);

  const rows = responses.map((response) => {
    const formInfo = forms.find((form) => form.formId === response.form);
    return (
      <Table.Tr key={`${response.formId}-${response.questionId}`}>
        <Table.Td onClick={() => handleUserClick(response.form)} style={{ cursor: "pointer" }}>
          <Flex spacing="sm" gap="xs" align="center">
            <ThemeIcon color="blue" size={isMobile ? "sm" : "md"} variant="light">
              <IconClipboardList size={isMobile ? 12 : 16} />
            </ThemeIcon>
            <Text size={isMobile ? "sm" : "md"}>{response.form}</Text>
            <ThemeIcon color="gray" size={isMobile ? "xs" : "sm"} variant="subtle">
              <IconChevronRight size={isMobile ? 10 : 14} />
            </ThemeIcon>
          </Flex>
        </Table.Td>
        <Table.Td>
          <Badge color="indigo" variant="light" size={isMobile ? "xs" : "sm"}>
            {formInfo?.formName || "N/A"}
          </Badge>
        </Table.Td>
        {!isMobile && <Table.Td>{formInfo?.formQuestion || "N/A"}</Table.Td>}
        {isAdmin ? (
          <Table.Td>
            <ResetForm customerId={userId} formId={response.form} />
          </Table.Td>
        ) : null}
      </Table.Tr>
    );
  });

  return (
    <Container size="lg" p={isMobile ? "xs" : "md"}>
      <Paper shadow="md" radius="lg" p={isMobile ? "md" : "xl"} withBorder mt="xl">
        <Stack spacing={isMobile ? "md" : "xl"}>
          <Group position="apart" align="center">
            <Group>
              <ThemeIcon size={isMobile ? "lg" : "xl"} radius="md" variant="light" color="blue">
                <IconUser size={isMobile ? 20 : 28} />
              </ThemeIcon>
              <Title order={isMobile ? 3 : 2}>Response Details</Title>
            </Group>
          </Group>

          <Text size={isMobile ? "md" : "lg"} weight={500}>
            Name:{" "}
            <Text component="span" c="blue" inherit>
              {customer.customerName}
            </Text>
          </Text>

          <Transition
            mounted={!loading}
            transition="fade"
            duration={400}
            timingFunction="ease"
          >
            {(styles) => (
              <div style={styles}>
                {loading ? (
                  <Group position="center">
                    <Loader size="xl" variant="dots" />
                  </Group>
                ) : (
                  <ScrollArea>
                    <Table striped highlightOnHover style={{ minWidth: isMobile ? 300 : 700 }}>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>Form ID</Table.Th>
                          <Table.Th>Form Name</Table.Th>
                          {!isMobile && <Table.Th>Question</Table.Th>}
                          {isAdmin && <Table.Th>Action</Table.Th>}
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody >{rows}</Table.Tbody>
                    </Table>
                  </ScrollArea>
                )}
              </div>
            )}
          </Transition>
          <Group justify="center">
            <ButtonTo
              buttonText={isAdmin ? 'Back to Report' : 'Back To Forms'}
              routeString={getRouteString}
              variant="outline"
              color="blue"
              radius="md"
              size={isMobile ? "sm" : "md"}
              leftIcon={<IconFileDescription size={isMobile ? 14 : 16} />}
              preProcess={() => { }}
              postProcess={() => { }}
            />
          </Group>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ResponseDetailsPage;