// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* nav {
  background-color: #282c34;
  padding: 15px 20px;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  margin-right: 20px;
}

nav ul li a:hover {
  text-decoration: underline;
} */


.nav-link {
  display: block;
  line-height: 1;
  padding: 12px 12px;
  border-radius: 4px;
  text-decoration: none;
  color: #495057;
  font-size: 16px;
  font-weight: 700;
  transition: background-color 0.2s ease;
}

.nav-link:hover {
  background-color: #495057;
  color:#e9ecef;
}



.auth {
  display: flex;
}

.sign-in {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.sign-up {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;GA0BG;;;AAGH;EACE,cAAc;EACd,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;;;AAIA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd","sourcesContent":["/* nav {\r\n  background-color: #282c34;\r\n  padding: 15px 20px;\r\n}\r\n\r\nnav ul {\r\n  list-style: none;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  padding: 0;\r\n  margin: 0;\r\n}\r\n\r\nnav ul li {\r\n  margin: 0 10px;\r\n}\r\n\r\nnav ul li a {\r\n  color: white;\r\n  text-decoration: none;\r\n  font-weight: bold;\r\n  margin-right: 20px;\r\n}\r\n\r\nnav ul li a:hover {\r\n  text-decoration: underline;\r\n} */\r\n\r\n\r\n.nav-link {\r\n  display: block;\r\n  line-height: 1;\r\n  padding: 12px 12px;\r\n  border-radius: 4px;\r\n  text-decoration: none;\r\n  color: #495057;\r\n  font-size: 16px;\r\n  font-weight: 700;\r\n  transition: background-color 0.2s ease;\r\n}\r\n\r\n.nav-link:hover {\r\n  background-color: #495057;\r\n  color:#e9ecef;\r\n}\r\n\r\n\r\n\r\n.auth {\r\n  display: flex;\r\n}\r\n\r\n.sign-in {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: 20px;\r\n}\r\n\r\n.sign-up {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  margin: 20px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
