import React, { useState, useEffect } from 'react';
import { Button, Modal, Text, Group } from '@mantine/core';
import { IconRestore } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { BASE_URL } from '../constants';
import { useAuth } from '@clerk/clerk-react';

const ResetForm = ({ customerId, formId }) => {
    const [opened, setOpened] = useState(false);
    const [isResetting, setIsResetting] = useState(false);
    const [isFormArchived, setIsFormArchived] = useState(false);
    const { getToken } = useAuth();

    useEffect(() => {
        const checkIfFormArchived = async () => {
            try {
                const token = await getToken();
                const response = await fetch(`${BASE_URL}/archived-forms/${customerId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const archivedForms = await response.json();
                    const formExists = archivedForms.some(
                        (form) => form.customerId === customerId && form.formId === formId
                    );
                    setIsFormArchived(formExists);
                } else {
                    throw new Error('Failed to fetch archived forms');
                }
            } catch (error) {
                console.error('Error checking archived forms:', error);
                setIsFormArchived(false); // Disable the button if there's an error
            }
        };

        checkIfFormArchived();
    }, [customerId, formId]);

    const handleReset = async () => {
        setIsResetting(true);
        try {
            const token = await getToken();
            const archivedDeleteResponse = await fetch(`${BASE_URL}/archived-forms/delete?customerId=${customerId}&formId=${formId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // onFormReset(formId);

            // const formDeleteResponse = await fetch(`${BASE_URL}/responses/batch?formId=${formId}&&customerId=${customerId}` , {
            //   method: 'DELETE',
            // });

            if (archivedDeleteResponse.ok) {
                notifications.show({
                    title: 'Success',
                    message: 'Form has been reset successfully!',
                    color: 'teal',
                });
                setIsFormArchived(false); // Disable the button as the form is reset
            } else {
                throw new Error('Failed to reset the form');
            }
        } catch (error) {
            notifications.show({
                title: 'Error',
                message: 'Failed to reset the form. Please try again later.',
                color: 'red',
            });
        } finally {
            setIsResetting(false);
            setOpened(false);
        }
    };

    return (
        <>
            <Button
                rightSection={<IconRestore size={14} />}
                color="teal"
                disabled={!isFormArchived}
                onClick={() => setOpened(true)}
            >
                Reset Form
            </Button>

            <Modal opened={opened} onClose={() => setOpened(false)} title="Confirm Reset">
                <Text>Are you sure you want to reset this form?</Text>
                <Group mt="md" position="right">
                    <Button color="gray" onClick={() => setOpened(false)}>Cancel</Button>
                    <Button color="red" loading={isResetting} onClick={handleReset}>
                        Confirm Reset
                    </Button>
                </Group>
            </Modal>
        </>
    );
};

export default ResetForm;