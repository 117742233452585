// src/pages/03-FormGAD7.jsx
import React from 'react';
import { useCustomer } from '../components/CustomerContext'; // Import the useCustomer hook
import ScaleForm from '../components/ScaleForm';

function FormISI() {
  const { customerId } = useCustomer(); // Access customerId from the context

  return (
    <div>
      <ScaleForm formId={2} customerId={customerId} />
    </div>
  );
}

export default FormISI;