// src/pages/03-FormGAD7.jsx
import React from 'react';
import { useCustomer } from '../components/CustomerContext'; // Import the useCustomer hook
import DynamicForm from '../components/DynamicForm';

function FormGAD7() {
  const { customerId } = useCustomer(); // Access customerId from the context

  return (
    <div>
      <DynamicForm formId={1} customerId={customerId} />
    </div>
  );
}

export default FormGAD7;