import React, { createContext, useState, useEffect, useContext } from "react";
import { useUser, useAuth } from "@clerk/clerk-react";
import { BASE_URL } from "../constants";

const CustomerContext = createContext();

export const CustomerProvider = ({ children }) => {
  const { isSignedIn, isLoaded, user } = useUser();
  const { getToken } = useAuth();
  const [customerId, setCustomerId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);  // Store admin status as a boolean

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (isLoaded && isSignedIn && user) {
        try {
          const email = user.emailAddresses[0].emailAddress; // Get the user's email from Clerk
          const token = await getToken();

          // Fetch customer ID
          const customerResponse = await fetch(`${BASE_URL}/customers/search?email=${email}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (customerResponse.ok) {
            const customerData = await customerResponse.json();
            setCustomerId(customerData.customerId); // Extract and set the customerId
          } else {
            console.error("Failed to fetch customer ID");
          }

          // Fetch admin status
          const adminResponse = await fetch(`${BASE_URL}/customers/is-admin?email=${email}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (adminResponse.ok) {
            const adminData = await adminResponse.json();
            setIsAdmin(adminData); // Set admin status
          } else {
            console.error("Failed to fetch admin status");
          }

        } catch (error) {
          console.error("Error fetching customer details:", error);
        }
      }
    };

    fetchCustomerDetails();
  }, [isLoaded, isSignedIn, user]);

  return (
    <CustomerContext.Provider value={{ customerId, isAdmin }}>
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomer = () => useContext(CustomerContext);
