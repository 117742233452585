import React, { useState, useEffect } from 'react';
import { Button, Group, Paper, Container, em, Space, Title, Text, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from "@tabler/icons-react";
import ButtonTo from '../pages/subpages/00-ButtonTo';
import { useUser, useAuth } from '@clerk/clerk-react';
import { BASE_URL } from '../constants';
import { useMediaQuery } from '@mantine/hooks';
import { useCustomer } from './CustomerContext';

function DynamicForm({ formId }) {
    const { customerId, loading: contextLoading } = useCustomer();
    const [disabled, setDisabled] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [answerOptions, setAnswerOptions] = useState([]);
    const [validResponses, setValidResponses] = useState([]);
    const [formDetails, setFormDetails] = useState({ formName: '', formQuestion: '' });
    const [customer, setCustomer] = useState({ name: '', email: '' });
    const isMobile = useMediaQuery('(max-width: 768px)');

    const { user } = useUser();
    const { getToken } = useAuth();

    // Modify the form to include validation logic for Select fields
    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            ...questions.reduce((acc, question) => ({ ...acc, [question.questionId.toString()]: '' }), {}),
        },
        validate: {
            name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
            email: (value) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email'),
            ...questions.reduce((acc, question) => ({
                ...acc,
                [question.questionId.toString()]: (value) => (value ? null : 'This field is required'), // Validation rule
            }), {}),
        },
        enhanceGetInputProps: () => ({ disabled }),
    });



    useEffect(() => {
        if (!customerId || contextLoading) return;

        async function fetchCustomerData() {
            try {
                const token = await getToken();
                const response = await fetch(`${BASE_URL}/customers/${customerId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.ok) {
                    const customerData = await response.json();
                    setCustomer(customerData);
                    form.setValues((prevValues) => ({
                        ...prevValues,
                        name: customerData.customerName,
                        email: customerData.email,
                    }));
                } else {
                    throw new Error('Failed to fetch customer data');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }

        async function fetchData() {
            try {
                const token = await getToken();
                const [formResponse, questionsResponse, answersResponse, validResponsesResponse] = await Promise.all([
                    fetch(`${BASE_URL}/forms/${formId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }),
                    fetch(`${BASE_URL}/questions/${formId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }),
                    fetch(`${BASE_URL}/answers/${formId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }),
                    fetch(`${BASE_URL}/validResponses/${formId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                ]);

                if (formResponse.ok && questionsResponse.ok && answersResponse.ok && validResponsesResponse.ok) {
                    const formData = await formResponse.json();
                    const questionsData = await questionsResponse.json();
                    const answersData = await answersResponse.json();
                    const validResponsesData = await validResponsesResponse.json();

                    // Map answers to the format expected by the Select component
                    const formattedAnswers = answersData.map(a => ({
                        value: String(a.answerId),
                        label: a.answerText
                    }));

                    // Update state with fetched data
                    setFormDetails(formData);
                    setQuestions(questionsData);
                    setAnswerOptions(formattedAnswers);
                    setValidResponses(validResponsesData);

                    // Update form initialValues to include dynamic questions with string keys
                    form.setValues(prevValues => ({
                        ...prevValues,
                        ...questionsData.reduce((acc, question) => ({
                            ...acc,
                            [question.questionId.toString()]: prevValues[question.questionId.toString()] || ''
                        }), {}),
                    }));
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                notifications.show({
                    title: "Error",
                    message: "Failed to load data.\n" + error.message,
                    color: "red",
                    icon: <IconX />,
                });
            }
        }

        fetchCustomerData().then(fetchData);
    }, [customerId, formId, contextLoading]);

    const validateAnswers = () => {
        return questions.every(question => {
            const answerId = form.values[question.questionId.toString()];
            return validResponses.some(response =>
                response.questionId === question.questionId && response.answerId === Number(answerId)
            );
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { hasErrors } = form.validate();
        if (hasErrors) {
            notifications.show({
                title: "Missing Fields",
                message: "Please fill in all required fields.",
                color: "red",
                autoClose: 6000,
                position: "bottom-right",
                icon: <IconX />,
            });
            return;
        }

        if (!validateAnswers()) {
            notifications.show({
                title: "Invalid Answers",
                message: "Some answers are not valid. Please check your responses.",
                color: "red",
                icon: <IconX />,
            });
            return;
        }

        try {
            //const now = new Date().toISOString();
            // New Time Function
            const originalTime = new Date();
            const timezoneOffset = originalTime.getTimezoneOffset();
            const now = new Date(originalTime.getTime() - timezoneOffset * 60000).toISOString();
            const token2 = await getToken();
            const responses = questions.map(question => ({
                questionId: question.questionId,
                answerId: Number(form.values[question.questionId.toString()]),
                time: now,
                form: formId,
                customer: customerId || 1
            }));

            const responseResult = await fetch(`${BASE_URL}/responses/batch`, {
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token2}` },
                body: JSON.stringify(responses),
            });

            if (!responseResult.ok) {
                throw new Error('Failed to submit responses');
            }

            const archiveUrl = `${BASE_URL}/archived-forms/archive?customerId=${customerId}&formId=${formId}`;
            const archiveResult = await fetch(archiveUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${token2}` },
                body: JSON.stringify({
                    formName: formDetails.formName,
                    completionTime: now,
                }),
            });

            if (!archiveResult.ok) {
                throw new Error('Failed to archive form');
            }

            notifications.show({
                title: "Success!!!",
                message: "All Done!",
                color: "teal",
                icon: <IconCheck />,
            });
            setDisabled(true);
        } catch (error) {
            notifications.show({
                title: "Error",
                message: "Failed to submit data.\n" + error.message,
                color: "red",
                icon: <IconX />,
            });
        }
    };

    return (
        <Paper shadow="xs" radius="xl" withBorder p="xl">
            <Container fluid w={isMobile ? '100%' : '60%'} h={em("100%")} ta="left" mx="auto">
                <Space h="xl" />
                <Title order={1}>{formDetails.formName} Form</Title>
                <Space h="xl" />

                <form onSubmit={handleSubmit}>
                    <TextInput
                        label="Name"
                        placeholder={user.fullName}
                        disabled
                        value={form.values.name}
                    />
                    <TextInput
                        mt="sm"
                        label="Email"
                        placeholder={user.primaryEmailAddress}
                        disabled
                        value={form.values.email}
                    />
                    <Space h="xl" />
                    <Text>{formDetails.formQuestion}</Text>
                    <Space h="xl" />

                    {questions.map((question) => {
                        let filteredAnswers;
                        const isFormPG9 = formId === 7;

                        if (isFormPG9) {
                            if (question.questionId >= 1 && question.questionId <= 9) {
                                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 1 && Number(a.value) <= 4);
                            } else {
                                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 5 && Number(a.value) <= 8);
                            }
                        } else {
                            filteredAnswers = answerOptions;
                        }

                        return (
                            <div key={question.questionId}>
                                <Select
                                    label={question.questionText}
                                    placeholder="Select option"
                                    data={filteredAnswers}
                                    allowDeselect
                                    clearable
                                    mt="md"
                                    value={form.values[question.questionId.toString()]}
                                    {...form.getInputProps(question.questionId.toString())}
                                    error={form.errors[question.questionId.toString()]}
                                    required
                                />
                                <Space h="xl" />
                            </div>
                        );
                    })}

                    <Group justify="center" mt="md">
                        <ButtonTo buttonText="Return" routeString="Forms" variant="subtle" preProcess={() => { }} postProcess={() => { }} />
                        {!disabled && <Button type="submit">Submit</Button>}
                    </Group>
                </form>
            </Container>
        </Paper>
    );
}

export default DynamicForm;
