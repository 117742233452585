import React, { useEffect, useState } from 'react';
import { Container, Card, Group, Paper, Loader, Transition, Box, Title, Stack, Text, ThemeIcon, Badge, Table, Flex, ScrollArea } from '@mantine/core';
import { IconClipboardList, IconClock, IconArrowLeft } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import ButtonTo from '../pages/subpages/00-ButtonTo';
import { BASE_URL, ieqBUItems, ieqSIItems, pcsRScoreItems, pcsMScoreItems, pcsHScoreItems, tskAAItems, tskSFItems } from '../constants';
import { useMediaQuery } from '@mantine/hooks';
import { getLevel, getScoringMap, assessment } from '../constants';
import Assessement from './Assessement';
import { useCustomer } from './CustomerContext';
import { useAuth } from '@clerk/clerk-react';

const FormDetailsPage = () => {
  const { userId, formId } = useParams();
  const { isAdmin } = useCustomer();
  const [question, setQuestion] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [submissions, setSubmissions] = useState([]);
  const { getToken } = useAuth();

  useEffect(() => {
    async function fetchUserResponse() {
      try {
        const token = await getToken();
        const response = await fetch(`${BASE_URL}/responses/batch?formId=${formId}&&customerId=${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const question = await fetch(`${BASE_URL}/questions/${formId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const answer = await fetch(`${BASE_URL}/answers/${formId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.ok && question.ok && answer.ok) {
          const data = await response.json();
          const questionRes = await question.json();
          const answerRes = await answer.json();

          setQuestion(questionRes);
          setAnswer(answerRes);

          // Group responses by submission time
          const submissionsMap = {};
          data.forEach((response) => {
            // const submissionTime = new Date(response.time).toLocaleString(); // Group by formatted time
             const submissionTime = new Date(response.time).toISOString(); //  NEW
	     if (!submissionsMap[submissionTime]) {
              submissionsMap[submissionTime] = [];
            }
            submissionsMap[submissionTime].push(response);
          });
          console.log("Group responses by submission time: ", submissionsMap)

          // Convert to array of objects
          const groupedSubmissions = Object.keys(submissionsMap).map((time) => ({
            time,
            responses: submissionsMap[time],
          }));
          console.log("Convert to array of objects: ", groupedSubmissions)

          // Sort by submission time (most recent first)
          const sortedSubmissions = groupedSubmissions.sort((a, b) => new Date(b.time) - new Date(a.time));

         //NEW FORMATTING
          const formattedSubmissions = sortedSubmissions.map(submission => ({
            ...submission,
            time: new Date(submission.time).toLocaleString(), // Format time for display purposes
          }));


          //setSubmissions(sortedSubmissions);
	    setSubmissions(formattedSubmissions);

        } else {
          throw new Error("Failed to fetch user responses");
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchUserResponse();
  }, [userId, formId]);


  console.log("Submission: ", submissions)

  const renderSubmissionRows = (responses) => {

    return responses.map((response) => {
      const questionInfo = question.find((q) => q.questionId === response.questionId);
      const answerInfo = answer.find((a) => a.answerId === response.answerId);

      return (
        <Table.Tr key={`${response.form}-${response.questionId}`}>
          <Table.Td style={{ width: isMobile ? '100%' : '500px', maxWidth: isMobile ? '100%' : '400px', maxHeight: '100px', overflow: 'auto' }}>
            <Flex justify="start" align="center" direction="row" gap="xs">
              <ThemeIcon color="blue" size={isMobile ? "sm" : "md"} variant="light" mr={isMobile ? 0 : 10} mb={isMobile ? 5 : 0}>
                {response.questionId}
              </ThemeIcon>
              <Text size={isMobile ? "sm" : "md"}>{questionInfo?.questionText}</Text>
            </Flex>
          </Table.Td>
          <Table.Td style={{ maxWidth: isMobile ? '100%' : '400px' }}>
            <Box
              p="xs"
              style={{
                backgroundColor: '#e6f7e6',
                borderRadius: '4px',
                maxWidth: '100%'
              }}
            >
              <Text c="green" fw={500} size={isMobile ? "sm" : "md"}>
                {answerInfo?.answerText}
              </Text>
            </Box>
          </Table.Td>
          {!isMobile && (
            <Table.Td>
              <Group spacing="xs">
                <ThemeIcon color="gray" size="sm" variant="subtle">
                  <IconClock size={14} />
                </ThemeIcon>
                <Text size="sm">{new Date(response.time).toLocaleString()}</Text>
              </Group>
            </Table.Td>
          )}
        </Table.Tr>
      );
    }
    )
  };

  return (
    <Container size="lg" p={isMobile ? "xs" : "md"}>
      <Card shadow="md" radius="lg" p={isMobile ? "md" : "xl"} withBorder mt="xl">
        <Stack spacing={isMobile ? "md" : "xl"}>
          <Group position="apart" align="center">
            <Group>
              <ThemeIcon size={isMobile ? "lg" : "xl"} radius="md" variant="light" color="blue">
                <IconClipboardList size={isMobile ? 20 : 28} />
              </ThemeIcon>
              <Title order={isMobile ? 3 : 2}>Form Details</Title>
            </Group>
          </Group>

          <Text size={isMobile ? "md" : "lg"} weight={500}>Form ID: <Text component="span" c="blue" inherit>{formId}</Text></Text>

          {/* Loop over each submission */}
          {submissions.map((submission, index) => {
            let total = 0, buScore = 0, siScore = 0, pcsRScore = 0, pcsMScore = 0, pcsHScore = 0, tskAAScore = 0, tskSFScore = 0;

            const scoringMap = getScoringMap(formId);

            submission.responses.forEach((response) => {
              const answerInfo = answer.find(a => a.answerId === response.answerId);
              const questionInfo = question.find(q => q.questionId === response.questionId);
              const score = scoringMap[answerInfo?.answerText?.toLowerCase()] || 0;
              total += score;

              // Calculate B/U, S/I, PCS, TSK scores based on form ID
              if (formId === '3') {
                if (ieqBUItems.includes(questionInfo.questionId)) {
                  buScore += score;
                } else if (ieqSIItems.includes(questionInfo.questionId)) {
                  siScore += score;
                }
              } else if (formId === '4') {
                if (pcsRScoreItems.includes(questionInfo.questionId)) {
                  pcsRScore += score;
                } else if (pcsMScoreItems.includes(questionInfo.questionId)) {
                  pcsMScore += score;
                } else if (pcsHScoreItems.includes(questionInfo.questionId)) {
                  pcsHScore += score;
                }
              } else if (formId === '5') {
                if (tskAAItems.includes(questionInfo.questionId)) {
                  tskAAScore += score;
                } else if (tskSFItems.includes(questionInfo.questionId)) {
                  tskSFScore += score;
                }
              }
            });

            const level = getLevel(total, formId);
            const assessmentTitle = assessment(formId);

            return (
              <Paper key={submission.time} shadow="sm" radius="md" p="md" withBorder mt="xl">
                {/* Highlight most recent submission */}
                {isAdmin && (
                  <Stack mb={20}>
                    <Text size={isMobile ? "md" : "lg"} weight={500}>
                      {index === 0 ? 'Most Recent Submission' : 'Previous Submission'} - <Text component="span" c="blue" inherit>{submission.time}</Text>
                    </Text>
                    <Text size={isMobile ? "md" : "lg"} weight={500}>
                      Total Score: <Text component="span" c="blue" inherit>{total}</Text>
                    </Text>
                    <Flex size={isMobile ? "md" : "lg"} weight={500} gap="md">
                      {assessmentTitle}
                      <Badge color={level.color} size="lg">{level.label}</Badge>
                    </Flex>

                    {/* Display detailed assessment */}
                    <Assessement
                      formId={formId}
                      buScore={buScore}
                      siScore={siScore}
                      pcsRScore={pcsRScore}
                      pcsMScore={pcsMScore}
                      pcsHScore={pcsHScore}
                      tskAAScore={tskAAScore}
                      tskSFScore={tskSFScore}
                    />
                  </Stack>
                )}

                <Transition mounted={!loading} transition="fade" duration={400} timingFunction="ease" mt={20}>
                  {(styles) => (
                    <div style={styles}>
                      {loading ? (
                        <Group position="center">
                          <Loader size="xl" variant="dots" />
                        </Group>
                      ) : (
                        <Paper shadow="sm" radius="md" p={isMobile ? "xs" : "md"} withBorder>
                          <ScrollArea>
                            <Table striped highlightOnHover style={{ minWidth: isMobile ? 300 : 700 }}>
                              <Table.Thead>
                                <Table.Tr>
                                  <Table.Th>Question</Table.Th>
                                  <Table.Th>Answer</Table.Th>
                                  {!isMobile && <Table.Th>Time</Table.Th>}
                                </Table.Tr>
                              </Table.Thead>
                              <Table.Tbody>{renderSubmissionRows(submission.responses)}</Table.Tbody>
                            </Table>
                          </ScrollArea>
                        </Paper>
                      )}
                    </div>
                  )}
                </Transition>

              </Paper>
            );
          })}


        </Stack>
      </Card>
      <Group justify="center" mt="md">
        <ButtonTo buttonText="Back to User Responses" routeString={`responses/${userId}`} variant="outline"
          color="blue"
          radius="md"
          size={isMobile ? "sm" : "md"}
          leftIcon={<IconArrowLeft size={isMobile ? 14 : 16} />}
          preProcess={() => { }}
          postProcess={() => { }}
        />
      </Group>
    </Container>
  );
};

export default FormDetailsPage;
