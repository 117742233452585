import "./App.css";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import { CustomerProvider } from "./components/CustomerContext"; // Import the CustomerProvider

import Welcome from "./pages/Welcome";
import MainMenu from "./pages/MainMenu";
import Forms from "./pages/Forms";
import FormGAD7 from "./pages/01-FormGAD7";
import FormISI from "./pages/02-FormISI";
import FormIEQ from "./pages/03-FormIEQ";
import FormPCS from "./pages/04-FormPCS";
import FormTKS from "./pages/05-FormTSK";
import FormSH from "./pages/06-FormSH";
import FormPQ9 from "./pages/07-FormPQ9";

import NavBar from "./components/NavBar";
import VIPPage from "./vipComponents/VipPage";
import ReportPage from "./components/ReportPage";
import ResponseDetailsPage from "./components/ResponseDetailsPage";
import FormDetailPage from "./components/FormDetailPage";
import CreateUser from "./components/CreateUser";
import SignUpPage from "./components/SignUpPage";
function App() {
  return (
    <MantineProvider>
      <Notifications autoClose={1200} position="top-left" zIndex={2} />
      <div className="App">
        <BrowserRouter>
          <CustomerProvider> {/* Wrap your routes with CustomerProvider */}
            <NavBar /> {/* Extracted NavBar component */}
            <Routes>
              <Route path="/" element={<Welcome />} />
              <Route path="/MainMenu" element={<MainMenu />} />
              <Route path="/Forms" element={<Forms />} />
              <Route path="/FormGAD7" element={<ProtectedRoute element={<FormGAD7 />} />} />
              <Route path="/FormISI" element={<ProtectedRoute element={<FormISI />} />} />
              <Route path="/FormIEQ" element={<ProtectedRoute element={<FormIEQ />} />} />
              <Route path="/FormPCS" element={<ProtectedRoute element={<FormPCS />} />} />
              <Route path="/FormSH" element={<ProtectedRoute element={<FormSH />} />} />
              <Route path="/FormPQ9" element={<ProtectedRoute element={<FormPQ9 />} />} />
              <Route path="/FormTSK" element={<ProtectedRoute element={<FormTKS />} />} />
              <Route path="/SignUp" element={<SignUpPage />} />
              <Route path="/responses/:userId" element={<ProtectedRoute element={<ResponseDetailsPage />} />} />
              <Route path="/responses/:userId/:formId" element={<ProtectedRoute element={<FormDetailPage />} />} />
              
              <Route path="/report" element={<ProtectedRoute element={<ReportPage />} role="admin" />} />
              <Route path="/create-user" element={<ProtectedRoute element={<CreateUser />} role="admin" />} />
            </Routes>
          </CustomerProvider> {/* Close CustomerProvider */}
        </BrowserRouter>
      </div>
    </MantineProvider>
  );
}

export default App;