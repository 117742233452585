import { Title, Space, Table } from '@mantine/core';
import { useEffect, useState } from "react";
import { notifications } from '@mantine/notifications';
import { IconX } from "@tabler/icons-react";
import { useAuth } from "@clerk/clerk-react";  // Import useAuth from Clerk

function DisplayMasters() {

    const [masters, setMasters] = useState([]);
    const { getToken } = useAuth();  // Get the token from useAuth

    useEffect(() => {
        getMasters();
    }, []);


    const getMasters = async () => {
        try {
            const token = await getToken();
            await
                fetch(`https://nopain.sunsab.com/api/masters`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,  // Include the token in the Authorization header
                    },
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Response Status Not 200 Error.')
                        };
                        return response.json();
                    })
                    .then((data) => {
                        setMasters(data);
                    });
        } catch (error) {
            console.error("Failed to fetch data", error);
            notifications.show({
                title: "Error",
                message: error.message,
                color: "red",
                icon: <IconX />,
            });
        }
    };

    const headers = ["ID", "Name", "Number"];
    const headersHead = headers.map((val) => (
        <Table.Th key={val}>{val}</Table.Th>
    ));

    const rows = masters.map((val, index) => (
        <Table.Tr key={index} ta="left">
            <Table.Td>{val.masterId}</Table.Td>
            <Table.Td>{val.name}</Table.Td>
            <Table.Td>{val.number}</Table.Td>
        </Table.Tr>
    ));

    return (
        <div>
            <Title order={4} >Masters</Title>
            <Space h="md" />
            <Table striped highlightOnHover withTableBorder withColumnBorders>
                <Table.Thead>
                    <Table.Tr>
                        {headersHead}
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
            </Table>
        </div>
    );
}
export default DisplayMasters;