import React from 'react';
import DynamicForm from '../components/DynamicForm';
import { useCustomer } from '../components/CustomerContext'; 
function FormTSK() {
    const { customerId } = useCustomer(); // Access customerId from the context

    return (
      <div>
        <DynamicForm formId={7} customerId={customerId} />
      </div>
    );
}

export default FormTSK;
