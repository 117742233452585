import { Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const Assessement = ({
    formId, 
    buScore,
    siScore,
    pcsRScore,
    pcsMScore,
    pcsHScore,
    tskAAScore,
    tskSFScore,
}) => {

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      {formId === "3" && (
        <>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            Blame/Unfairness (B/U) Score:{" "}
            <Text component="span" c="blue" inherit>
              {buScore}
            </Text>
          </Text>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            Severity/Irreparable (S/I) Score:{" "}
            <Text component="span" c="blue" inherit>
              {siScore}
            </Text>
          </Text>
        </>
      )}
      {formId === "4" && (
        <>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            Rumination Score:{" "}
            <Text component="span" c="blue" inherit>
              {pcsRScore}
            </Text>
          </Text>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            Magnification Score:{" "}
            <Text component="span" c="blue" inherit>
              {pcsMScore}
            </Text>
          </Text>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            Helplessness Score:{" "}
            <Text component="span" c="blue" inherit>
              {pcsHScore}
            </Text>
          </Text>
        </>
      )}
      {formId === "5" && (
        <>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            TSK-AA Activity Avoidance Score:{" "}
            <Text component="span" c="blue" inherit>
              {tskAAScore}
            </Text>
          </Text>
          <Text size={isMobile ? "md" : "lg"} weight={500}>
            TSK-SF Somatic Focus Score:{" "}
            <Text component="span" c="blue" inherit>
              {tskSFScore}
            </Text>
          </Text>
        </>
      )}
    </>
  );
};

export default Assessement;
