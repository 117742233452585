"use client"

import { Container, Table, Title, Group, Paper, Text, ThemeIcon, Loader, Transition, Badge, Pagination, TextInput, Stack, ScrollArea, Flex } from '@mantine/core';
import { IconUsers, IconUserCircle, IconChevronRight, IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonTo from '../pages/subpages/00-ButtonTo';
import { BASE_URL } from '../constants';
import FetchCustomerData from '../components/FetchCustomerData';
import { useAuth } from '@clerk/clerk-react';
import { useMediaQuery } from '@mantine/hooks';

const ReportPage = () => {
  const [userResponses, setUserResponses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const pageSize = 10;
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const handleUserClick = (userId) => {
    navigate(`/responses/${userId}`);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const token = await getToken();
        const responseRes = await fetch(`${BASE_URL}/responses`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const customerRes = await fetch(`${BASE_URL}/customers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (responseRes.ok && customerRes.ok) {
          const responseData = await responseRes.json();
          const customerData = await customerRes.json();

          const uniqueResponses = responseData.reduce((acc, current) => {
            const isDuplicate = acc.find(user => user.customer === current.customer);
            if (!isDuplicate) {
              acc.push(current);
            }
            return acc;
          }, []);

          setUserResponses(uniqueResponses);
          setCustomers(customerData);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        console.log("Error:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  //Search Query
  const filteredResponses = userResponses.filter((response) => {
    const customerInfo = customers.find(customer => customer.customerId === response.customer);
    const searchString = searchQuery.toLowerCase();

    return (
      response.customer.toLowerCase().includes(searchString) ||
      customerInfo?.customerName?.toLowerCase().includes(searchString) ||
      customerInfo?.email?.toLowerCase().includes(searchString)
    );
  });

  const totalPages = Math.ceil(filteredResponses.length / pageSize);

  const paginatedResponses = filteredResponses.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const rows = paginatedResponses.map((response, index) => {
    const customerInfo = customers.find(customer => customer.customerId === response.customer);
    const userOrder = (currentPage - 1) * pageSize + index + 1; // Calculate the user's order number

    return (
      <Table.Tr key={response.customer}>
        <Table.Td onClick={() => handleUserClick(response.customer)} style={{ cursor: 'pointer' }}>
          <Group spacing="sm">
            <Flex justify="center" align="center" direction="row" gap="xs">
              <ThemeIcon color="blue" variant="light">
                <IconUserCircle size={isMobile ? 12 : 16} />
              </ThemeIcon>
              <Text>{userOrder}</Text>
              <ThemeIcon color="gray" size={isMobile ? "xs" : "sm"} variant="subtle">
                < IconChevronRight size={isMobile ? 10 : 14} />
              </ThemeIcon>
            </Flex>
          </Group>
        </Table.Td>
        <Table.Td>{customerInfo?.customerName || "N/A"}</Table.Td>
        <Table.Td>{customerInfo?.email || "N/A"}</Table.Td>
        <Table.Td>
          <FetchCustomerData customerId={response.customer} />
        </Table.Td>
        <Table.Td>

        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <Container size="lg" p={isMobile ? "xs" : "md"}>
      <Paper shadow="md" radius="lg" p={isMobile ? "md" : "xl"} withBorder mt="xl">
        <Stack spacing={isMobile ? "md" : "xl"}>
          <Group position="apart" align="center">
            <Group>
              <ThemeIcon size={isMobile ? "lg" : "xl"} radius="md" variant="light" color="blue">
                <IconUsers size={isMobile ? 20 : 28} />
              </ThemeIcon>
              <Title order={isMobile ? 3 : 2}>User Responses Report</Title>
            </Group>
          </Group>

          <TextInput
            icon={<IconSearch size={16} />}
            placeholder="Search by user ID, name, or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            size={isMobile ? "sm" : "md"}
            radius="md"
            style={{ maxWidth: isMobile ? '100%' : '30%' }}
          />

          <Transition mounted={!loading} transition="fade" duration={400} timingFunction="ease">
            {(styles) => (
              <div style={styles}>
                {loading ? (
                  <Group position="center">
                    <Loader size="xl" variant="dots" />
                  </Group>
                ) : (
                  <ScrollArea>
                    <Table striped highlightOnHover style={{ minWidth: isMobile ? 300 : 700 }}>
                      <Table.Thead>
                        <Table.Tr>
                          <Table.Th>User No.</Table.Th>
                          <Table.Th>Name</Table.Th>
                          <Table.Th>Email</Table.Th>
                          <Table.Th>Download</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                  </ScrollArea>
                )}
              </div>
            )}
          </Transition>

          <Group gap={5} justify="center">
            <Pagination
              total={totalPages}
              page={currentPage}
              onChange={setCurrentPage}
              size={isMobile ? "sm" : "md"}
            />
          </Group>

          <Group justify="center">
            <ButtonTo
              buttonText="Back to Forms"
              routeString=""
              variant="outline"
              color="blue"
              radius="md"
              size={isMobile ? "sm" : "md"}
              preProcess={() => { }}
              postProcess={() => { }}
            />
          </Group>
        </Stack>
      </Paper>
    </Container>
  );
};

export default ReportPage;