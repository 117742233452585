
import React from 'react';
import DynamicForm from '../components/DynamicForm';
import { useCustomer } from '../components/CustomerContext';

function FormPCS() {
const { customerId } = useCustomer(); // Access customerId from the context

  return (
    <div>
      <DynamicForm formId={4} customerId={customerId} />
    </div>
  );
}

export default FormPCS;
