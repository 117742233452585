import React, { useState, useEffect } from "react";
import { Button, Group, Paper, Container, em, Space, Title, Text, Select, TextInput, Center } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import ButtonTo from "../pages/subpages/00-ButtonTo";
import { Slider } from "@mantine/core";
import { useUser, useAuth } from "@clerk/clerk-react";
import { BASE_URL } from "../constants";
import { useMediaQuery } from '@mantine/hooks';


function ScaleForm({ formId, customerId }) {

  const { user } = useUser();
  const { getToken } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [validResponses, setValidResponses] = useState([]);
  const [formDetails, setFormDetails] = useState({
    formName: "",
    formQuestion: "",
  });

  const [customer, setCustomer] = useState({ name: '', email: '' });
  const isMobile = useMediaQuery('(max-width: 768px)');

  const form = useForm({
    initialValues: {
      name: '',
      email: '',
      ...questions.reduce((acc, question) => ({
        ...acc,
        [question.questionId.toString()]: '',
      }), {}),
    },
    validate: {
      name: (value) => (value.length < 2 ? 'Name must have at least 2 letters' : null),
      email: (value) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? null : 'Invalid email'),
      ...questions.reduce((acc, question) => ({
        ...acc,
        [question.questionId.toString()]: (value) => (value ? null : 'This field is required'),
      }), {}),
    },
  });



  useEffect(() => {
    async function fetchCustomerData() {
      try {
        const token = await getToken();
        const response = await fetch(`${BASE_URL}/customers/${customerId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const customerData = await response.json();
          setCustomer(customerData);
          form.setValues((prevValues) => ({
            ...prevValues,
            name: customerData.customerName,
            email: customerData.email,
          }));
        } else {
          throw new Error('Failed to fetch customer data');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }

    async function fetchData() {
      try {
        const token = await getToken();
        const [
          formResponse,
          questionsResponse,
          answersResponse,
          validResponsesResponse,
        ] = await Promise.all([
          fetch(`${BASE_URL}/forms/${formId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${BASE_URL}/questions/${formId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${BASE_URL}/answers/${formId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
          fetch(`${BASE_URL}/validResponses/${formId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        ]);

        if (
          formResponse.ok &&
          questionsResponse.ok &&
          answersResponse.ok &&
          validResponsesResponse.ok
        ) {
          const formData = await formResponse.json();
          const questionsData = await questionsResponse.json();
          const answersData = await answersResponse.json();
          const validResponsesData = await validResponsesResponse.json();

          // Map answers to the format expected by the Select component
          const formattedAnswers = answersData.map((a) => ({
            value: String(a.answerId),
            label: a.answerText,
          }));

          console.log(questionsData);
          console.log(formattedAnswers);

          // Update state with fetched data
          setFormDetails(formData);
          setQuestions(questionsData);
          setAnswerOptions(formattedAnswers);
          setValidResponses(validResponsesData);

          // Update form initialValues to include dynamic questions with string keys
          form.setValues((prevValues) => ({
            ...prevValues,
            ...questionsData.reduce(
              (acc, question) => ({
                ...acc,
                [question.questionId.toString()]:
                  prevValues[question.questionId.toString()] || "",
              }),
              {}
            ),
          }));
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        notifications.show({
          title: "Error",
          message: "Failed to load data.\n" + error.message,
          color: "red",
          icon: <IconX />,
        });
      }
    }

    fetchCustomerData();
    fetchData();
  }, [formId]);

  const validateAnswers = () => {
    return questions.every((question) => {
      const answerId = form.values[question.questionId.toString()];
      return validResponses.some(
        (response) =>
          response.questionId === question.questionId &&
          response.answerId === Number(answerId)
      );
    });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { hasErrors } = form.validate();
    if (hasErrors) {
      notifications.show({
        title: "Missing Fields",
        message: "Please fill in all required fields.",
        color: "red",
        autoClose: 6000,
        position: "bottom-right",
        icon: <IconX />,
      });
      return;
    }


    if (!validateAnswers()) {
      console.log("Submission aborted due to validation errors.");
      return;
    }

    try {
      //const now = new Date().toISOString();
      // New Time Function
      const originalTime = new Date();
      const timezoneOffset = originalTime.getTimezoneOffset();
      const now = new Date(originalTime.getTime() - timezoneOffset * 60000).toISOString();

      const token = await getToken();
      const responses = questions.map((question) => ({
        questionId: question.questionId,
        answerId: Number(form.values[question.questionId.toString()]),
        time: now,
        form: formId,
        customer: customerId || 1, // Use dynamic customer ID if provided
      }));

      console.log("Responses to submit:", responses);

      const responseResult = await fetch(
        `${BASE_URL}/responses/batch`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
          body: JSON.stringify(responses),
        }
      );

      if (!responseResult.ok) {
        throw new Error("Failed to submit responses");
      }
      // Archive the form for the customer after successful submission
      const archiveUrl = new URL(`${BASE_URL}/archived-forms/archive`);
      archiveUrl.searchParams.append('customerId', customerId);
      archiveUrl.searchParams.append('formId', formId);

      const archiveResult = await fetch(archiveUrl.toString(), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify({
          formName: formDetails.formName,
          completionTime: now
        })
      });

      if (!archiveResult.ok) {
        throw new Error('Failed to archive form');
      }

      console.log("All responses submitted successfully.");
      notifications.show({
        title: "Success!!!",
        message: "All Done!",
        color: "teal",
        icon: <IconCheck />,
      });
      setDisabled(true);
    } catch (error) {
      console.log("Submission failed:", error.message);
      notifications.show({
        title: "Error",
        message: "Failed to submit data.\n" + error.message,
        color: "red",
        icon: <IconX />,
      });
    }
  };

  const renderTitle = (questionId) => {
    if (questionId === 1) return <Center order={2}><Title>MEANING</Title></Center>;
    if (questionId === 3) return <Center order={2}><Title>FORGIVENESS</Title></Center>;
    if (questionId === 5) return <Center order={2}><Title>RELATEDNESS</Title></Center>;
    if (questionId === 7) return <Center order={2}><Title>HOPE</Title></Center>;
    return null;
  };

  const getMarks = (filteredAnswers) => {
    return filteredAnswers.map((a) => ({
      value: Number(a.value),
      label: (
        <div style={{ textAlign: 'center', fontSize: '14px' }}>
          {a.label.split(' ').slice(0, 4).join(' ')}<br />
          {a.label.split(' ').slice(4, 8).join(' ')}<br />
          {a.label.split(' ').slice(8, 12).join(' ')}<br />
          {a.label.split(' ').slice(12).join(' ')}<br />
        </div>
      ),
    }));
  };


  useEffect(() => {

    questions.forEach((question) => {
      const filteredAnswers = getFilteredAnswers(question);
      const marks = getMarks(filteredAnswers);
      const defaultValue = marks[0]?.value || 0;

      if (!form.values[question.questionId.toString()]) {
        form.setFieldValue(question.questionId.toString(), defaultValue.toString());
      }
    });
  }, [questions]);


  const getFilteredAnswers = (question) => {
    let filteredAnswers;

    if (formId === 6) {
      if (question.questionId >= 1 && question.questionId <= 2) {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 1 && Number(a.value) <= 5);
      } else if (question.questionId >= 3 && question.questionId <= 4) {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 6 && Number(a.value) <= 10);
      } else if (question.questionId >= 5 && question.questionId <= 6) {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 11 && Number(a.value) <= 15);
      } else {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 16 && Number(a.value) <= 20);
      }
    } else {
      if (question.questionId >= 1 && question.questionId <= 3) {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 1 && Number(a.value) <= 5);
      } else if (question.questionId === 4) {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 6 && Number(a.value) <= 10);
      } else if (question.questionId >= 5 && question.questionId <= 7) {
        filteredAnswers = answerOptions.filter(a => Number(a.value) >= 11 && Number(a.value) <= 15);
      } else {
        filteredAnswers = answerOptions;
      }
    }

    return filteredAnswers;
  };

  const renderSlider = (question, filteredAnswers) => {
    const marks = getMarks(filteredAnswers);
    const defaultValue = marks[0]?.value || 0;

    return (
      <>
        <Text size="sm" fw={500}>{question.questionText}</Text>
        <Slider
          required
          label={null}
          min={Math.min(...marks.map((mark) => mark.value))}
          max={Math.max(...marks.map((mark) => mark.value))}
          step={1}
          value={Number(form.values[question.questionId.toString()]) || defaultValue}
          {...form.getInputProps('value')}
          onChange={(value) =>
            form.setFieldValue(
              question.questionId.toString(),
              value.toString()
            )
          }
          marks={marks}
        />
        <Space h="xl" />
        <Space h="xl" />
      </>
    );
  };

  return (
    <Paper shadow="xs" radius="xl" withBorder p="xl">
      <Container fluid w={isMobile ? '100%' : '60%'} h={em("100%")} ta="left" mx="auto">
        <Space h="xl" />
        <Title order={1}>{formDetails.formName} Form</Title>
        <Space h="xl" />

        <form onSubmit={postData}>
          <TextInput
            label="Name"
            placeholder={user.fullName}
            disabled
            value={form.values.name}
          // {...form.getInputProps('name')}
          />
          <TextInput
            mt="sm"
            label="Email"
            placeholder={user.primaryEmailAddress}
            disabled
            value={form.values.email}
          // {...form.getInputProps('email')}
          />
          <Space h="xl" />
          <Text>{formDetails.formQuestion}</Text>
          <Space h="xl" />

          {questions.map((question) => {
            let filteredAnswers;
            const isFormSH = formId === 6;

            if (isFormSH) {

              if (question.questionId >= 1 && question.questionId <= 2) {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 1 && Number(a.value) <= 5);

              } else if (question.questionId >= 3 && question.questionId <= 4) {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 6 && Number(a.value) <= 10);

              } else if (question.questionId >= 5 && question.questionId <= 6) {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 11 && Number(a.value) <= 15);

              } else {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 16 && Number(a.value) <= 20);
              }

            } else {
              if (question.questionId >= 1 && question.questionId <= 3) {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 1 && Number(a.value) <= 5);
              } else if (question.questionId === 4) {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 6 && Number(a.value) <= 10);

              } else if (question.questionId >= 5 && question.questionId <= 7) {
                filteredAnswers = answerOptions.filter(a => Number(a.value) >= 11 && Number(a.value) <= 15);

              } else {
                filteredAnswers = answerOptions;
              }
            }

            return (
              <div key={question.questionId}>
                {isFormSH && !isMobile || question.questionId === 4 && !isMobile || (question.questionId >= 5 && question.questionId <= 7 && !isMobile) ? (
                  <>
                    {isFormSH && renderTitle(question.questionId)}
                    {renderSlider(question, filteredAnswers)}
                  </>
                ) : (
                  <Select
                    label={question.questionText}
                    placeholder="Select option"
                    data={filteredAnswers}
                    allowDeselect
                    clearable
                    mt="md"
                    value={form.values[question.questionId.toString()] || ''}
                    {...form.getInputProps(question.questionId.toString())}
                    required
                  />
                )}
                <Space h="xl" />
              </div>
            );
          })}

          <Group justify="center" mt="md">
            <ButtonTo
              buttonText="Return"
              routeString="Forms"
              variant="subtle"
              preProcess={() => { }}
              postProcess={() => { }}
            />
            {!disabled && <Button type="submit">Submit</Button>}
          </Group>
        </form>
      </Container>
    </Paper>
  );
}

export default ScaleForm;
