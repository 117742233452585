"use client";

import React, { useState } from 'react';
import * as XLSX from 'xlsx-js-style';
import { BASE_URL } from "../constants";
import { Button } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { useAuth } from '@clerk/clerk-react';

const FetchCustomerData = ({ customerId }) => {
  const [combinedData, setCombinedData] = useState(null);
  const { getToken } = useAuth();

  const handleDownload = async () => {
    try {
      // Fetch customer and response data
      const token = await getToken();
      const customerRes = await fetch(`${BASE_URL}/customers/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseRes = await fetch(`${BASE_URL}/responses/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (customerRes.ok && responseRes.ok) {
        const customerInfo = await customerRes.json();
        const responses = await responseRes.json();

        // Combine customer info with responses
        const combinedData = responses.map(response => ({
          customerId: customerInfo.customerId,
          customerName: customerInfo.customerName,
          customerEmail: customerInfo.email,
          form: response.form,
          questionId: response.questionId,
          answerId: response.answerId,
          time: response.time,
        }));

        setCombinedData(combinedData);

        // Prepare data for Excel
        const ws = XLSX.utils.json_to_sheet(combinedData, {
          header: [
            'customerId',
            'customerName',
            'customerEmail',
            'form',
            'questionId',
            'answerId',
            'time',
          ],
        });

        // Style header row
        const headerStyle = {
          fill: { fgColor: { rgb: "FFFF00" } }, // Yellow background
          font: { bold: true },
          alignment: { horizontal: "center" },
        };

        // Apply style to header row
        const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1'];
        headerCells.forEach(cell => {
          if (ws[cell]) ws[cell].s = headerStyle;
        });

        // Adjust column widths
        ws['!cols'] = [
          { wpx: 150 }, // Width for customerId column
          { wpx: 200 }, // Width for customerName column
          { wpx: 250 }, // Width for customerEmail column
          { wpx: 100 }, // Width for form column
          { wpx: 100 }, // Width for questionId column
          { wpx: 100 }, // Width for answerId column
          { wpx: 200 }  // Width for time column
        ];

        // Create a new workbook and append the sheet
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Customer Responses');

        // Generate Excel file and trigger download
        XLSX.writeFile(wb, `Customer_${customerId}_Data.xlsx`);
      } else {
        throw new Error('Failed to fetch data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <Button rightSection={<IconDownload size={14} />} onClick={handleDownload} >
        Download
      </Button>
    </div>
  );
};

export default FetchCustomerData;
