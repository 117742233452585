
import React from 'react';
import DynamicForm from '../components/DynamicForm';
import { useCustomer } from '../components/CustomerContext';

function FormIEQ() {
    const { customerId } = useCustomer(); // Access customerId from the context

    return (
      <div>
        <DynamicForm formId={3} customerId={customerId} />
      </div>
    );
}

export default FormIEQ;
