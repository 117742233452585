import React, { useState, useEffect } from 'react';
import { Container, Paper, Title, Grid, Text, ThemeIcon, Box, Group, Transition, Button, Flex } from '@mantine/core';
import { IconClipboardList, IconArrowLeft, IconCheck } from '@tabler/icons-react';
import ButtonTo from './subpages/00-ButtonTo';
import { BASE_URL } from '../constants';
import { useCustomer } from '../components/CustomerContext'; // Adjust path if necessary
import { useMediaQuery } from '@mantine/hooks';
import { useAuth } from '@clerk/clerk-react';

const formData = [
    { name: "GAD7", route: "FormGAD7", description: "Generalized Anxiety Disorder Assessment" },
    { name: "ISI", route: "FormISI", description: "Insomnia Severity Index" },
    { name: "IEQ", route: "FormIEQ", description: "Injustice Experience Questionnaire" },
    { name: "PCS", route: "FormPCS", description: "Pain Catastrophizing Scale" },
    { name: "TSK", route: "FormTSK", description: "Tampa Scale for Kinesiophobia" },
    { name: "SH Assessment", route: "FormSH", description: "Sexual Health Assessment" },
    { name: "PQ9", route: "FormPQ9", description: "Patient Health Questionnaire" },
];



function MainMenu() {
    const [mounted, setMounted] = useState(false);
    const [archivedForms, setArchivedForms] = useState(new Set());
    const { customerId } = useCustomer();
    const { getToken } = useAuth();

    const isMobile = useMediaQuery('(max-width: 768px)');

    useEffect(() => {
        const fetchArchivedForms = async () => {
            if (customerId) {
                try {
                    const token = await getToken();
                    const response = await fetch(`${BASE_URL}/archived-forms/${customerId}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    if (response.ok) {
                        const archivedData = await response.json();
                        setArchivedForms(new Set(archivedData.map(form => form.formName)));
                        console.log(archivedForms);
                    } else {
                        console.error('Failed to fetch archived forms');
                    }
                } catch (error) {
                    console.error('Error fetching archived forms:', error);
                }
            }
        };

        fetchArchivedForms();
    }, [customerId]);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <Container size="sm" py="xl">
            <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
                {(styles) => (
                    <Paper shadow="xl" radius="lg" withBorder p="xl" style={styles}>
                        <Box mb="xl">
                            <Flex gap="sm" justify="center" align="center" direction="row">
                                <ThemeIcon size="xl" radius="md" variant="light" color="blue">
                                    <IconClipboardList size={32} stroke={1.5} />
                                </ThemeIcon>
                                <Title order={1} style={{ fontSize: isMobile ? '28px' : '50px', fontWeight: 900, color: '#444', textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}>
                                    Assessment Forms
                                </Title>
                            </Flex>
                        </Box>

                        <Grid gutter="lg">
                            {formData.map((form, index) => (
                                <Grid.Col key={index} xs={12} sm={6} md={4}>
                                    <Paper shadow="md" radius="md" p="md">
                                        <Title order={3} mb="xs">{form.name}</Title>
                                        <Text size="sm" c="dimmed" mb="md">{form.description}</Text>
                                        {archivedForms.has(form.name) ? (
                                            <Group position="center" spacing="xs">
                                                <ThemeIcon color="teal" variant="light">
                                                    <IconCheck size={16} stroke={1.5} />
                                                </ThemeIcon>
                                                <Text>Completed</Text>
                                            </Group>
                                        ) : (
                                            <ButtonTo
                                                buttonText={`Start ${form.name}`}
                                                routeString={form.route}
                                                variant="gradient"
                                                gradient={{ from: 'blue', to: 'cyan' }}
                                                fullWidth
                                                preProcess={() => { }} postProcess={() => { }}
                                            />
                                        )}
                                    </Paper>
                                </Grid.Col>
                            ))}
                        </Grid>

                        <Box mt="xl" style={{ textAlign: 'center' }}>
                            <ButtonTo
                                preProcess={() => { }} postProcess={() => { }}
                                buttonText="Return"
                                routeString=""
                                variant="subtle"
                                leftIcon={<IconArrowLeft size={16} />}
                                styles={(theme) => ({
                                    root: {
                                        color: theme.colors.dark[9],
                                        '&:hover': {
                                            backgroundColor: theme.fn.rgba(theme.colors.dark[9], 0.1),
                                        },
                                    },
                                })}
                            />
                        </Box>
                    </Paper>
                )}
            </Transition>
        </Container>
    );
}

export default MainMenu;