import { useNavigate } from "react-router-dom";
import { Button } from '@mantine/core';

function ButtonTo({ buttonText, routeString, variant, preProcess, postProcess }) {
    const navigate = useNavigate();

    const goTo = () => {
        if (preProcess) preProcess();
        const path = typeof routeString === 'function' ? routeString() : routeString;
        navigate('/' + path);
        if (postProcess) postProcess();
    };

    return (
        <Button variant={variant} color="blue" onClick={goTo}>
            {buttonText}
        </Button>
    );
}

export default ButtonTo;