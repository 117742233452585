import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SignedIn, SignedOut, UserButton, SignInButton } from "@clerk/clerk-react";
import { useCustomer } from "./CustomerContext";
import { Container, Group, Button, Text, rem, ThemeIcon, Box, Burger, Paper } from '@mantine/core';
import { IconForms, IconVip, IconClipboardList } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useClickOutside } from '@mantine/hooks';

function NavBar() {
  const { customerId, isAdmin } = useCustomer();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [opened, setOpened] = useState(false);


  const ref = useClickOutside(() => setOpened(false));

  return (
    <Box component="header" h={68} bg="gray.2" style={{ borderBottom: '1px solid #e9ecef' }} ref={ref}>
      <Container h="100%">
        <Group justify="space-between" h="100%">
          <Link to="/" className="nav-link">
            <Group>
              <ThemeIcon size="lg" variant="gradient" gradient={{ from: 'indigo', to: 'cyan' }}>
                <IconForms style={{ width: rem(20), height: rem(20) }} />
              </ThemeIcon>
              <Text size="lg" fw={700}>FORMS</Text>
            </Group>
          </Link>

          {isMobile && (
            <Burger opened={opened} onClick={() => setOpened((o) => !o)} size="sm" />
          )}

          {(opened || !isMobile) && (
            <Paper
              radius="md"
              style={{
                position: isMobile ? 'absolute' : 'static',
                top: isMobile ? '68px' : 'unset',
                right: isMobile ? '0' : 'unset',
                width: isMobile ? '100%' : 'auto',
                background: isMobile ? 'white' : 'transparent',
                zIndex: 100,
                padding: isMobile ? '10px' : 'unset'
              }}
            >
              <Group direction="column" spacing="md" style={{ display: isMobile ? 'block' : 'flex' }}>
                <Link to="/forms" className="nav-link">
                  <Group gap="xs">
                    <IconForms style={{ width: rem(16), height: rem(16) }} />
                    <span>Forms</span>
                  </Group>
                </Link>

                {isAdmin && (
                  <Link to="/create-user" className="nav-link">
                    <Group gap="xs">
                      <IconVip style={{ width: rem(16), height: rem(16) }} />
                      <span>CreateUser</span>
                    </Group>
                  </Link>
                )}


                {isAdmin && (
                  <SignedIn>
                    <Link to="/report" className="nav-link">
                      <Group gap="xs">
                        <IconClipboardList style={{ width: rem(16), height: rem(16) }} />
                        <span>Reports</span>
                      </Group>
                    </Link>
                  </SignedIn>
                )}


                {!isAdmin && (
                  <SignedIn>
                    <Link to={`/responses/${customerId}`} className="nav-link">
                      <Group gap="xs">
                        <IconClipboardList style={{ width: rem(16), height: rem(16) }} />
                        <span>Response</span>
                      </Group>
                    </Link>
                  </SignedIn>
                )}


                <SignedOut>
                  <SignInButton mode="modal" >
                    <Button variant="default">Sign in</Button>
                  </SignInButton>
                </SignedOut>


                <SignedIn >
                  <Group className="nav-link">
                    <UserButton
                      appearance={{
                        elements: {
                          avatarBox: {
                            width: rem(30),
                            height: rem(30),
                          }
                        }
                      }}
                    />
                  </Group>
                </SignedIn>

              </Group>
            </Paper>
          )}
        </Group>
      </Container>
    </Box>
  );
}

export default NavBar;