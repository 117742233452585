import {em,Container,Space,Paper,Title} from "@mantine/core";
import { notifications } from '@mantine/notifications';
import { IconCheck } from "@tabler/icons-react";
import ButtonTo from './subpages/00-ButtonTo';
import DisplayMasters from './subpages/EX-DisplayMasters';

const goTo = () => {
    notifications.show({
        title: "Success!!!",
        message: "Awesome!",
        color: "blue",
        icon: <IconCheck />,
    });
};

function MainMenu() {

    sessionStorage.setItem("value",1)    
    sessionStorage.removeItem("value")

    return (
        <Paper shadow="xs" radius="xl" withBorder p="xl">
            <Container fluid w={em("50%")} h={em("100%")} ta="center" mx="auto">                
                <Space h="xl" />
                <Title order={1}>Main Menu</Title>  
                <Space h="xl" />
                <ButtonTo buttonText="Return" routeString="" variant="filled" preProcess={goTo} postProcess={() => { }} />
                <Space h="xl" />
                <DisplayMasters/>
            </Container>
        </Paper>
    );
}
export default MainMenu;