import { Navigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { useCustomer } from "../components/CustomerContext"; // Import useCustomer
import { useUser, SignIn } from "@clerk/clerk-react";
import { Loader } from "@mantine/core";

const ProtectedRoute = ({ element, role }) => {
  const { isAdmin } = useCustomer();
  const { isLoaded, isSignedIn } = useUser();

  if (!isLoaded) {
    return <Loader />;  // Show a loader while waiting for user data to load
  }

  if (!isSignedIn) {
    showNotification({
      id: 'auth-required',
      title: 'Authentication Required',
      message: 'You need to log in first before accessing this page.',
      color: 'red',
      autoClose: 8000,
      position: 'top-right',
    });
    return (
      <div className="sign-in"><SignIn /></div>
    );
  }

  if (role === 'admin' && !isAdmin) {
    showNotification({
      id: 'vip-access-denied',
      title: 'Access Denied',
      message: 'You do not have access to this page.',
      color: 'red',
      autoClose: 5000,
      position: 'top-right',
    });

    return <Navigate to="/" replace />;
  }

  return element;
};


export default ProtectedRoute;