import { em, Container, Paper, Title, Text, Group, Transition, Box, Image, Stack, ThemeIcon, Divider, Grid } from '@mantine/core';
import { IconWaveSine, IconClipboardList, IconUser, IconMail } from '@tabler/icons-react';
import ButtonTo from './subpages/00-ButtonTo';
import { useUser } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';

function Welcome() {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    
    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <Container size="md" py="xl">
            <Transition mounted={mounted} transition="fade" duration={400} timingFunction="ease">
                {(styles) => (
                    <Paper shadow="xl" radius="lg" withBorder p={isMobile ? "md" : "xl"} style={styles} sx={(theme) => ({
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                        backgroundImage: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
                    })}>
                        <Stack spacing={isMobile ? "md" : "xl"} align="center">
                            <Group position="center" spacing="xs">
                                <IconWaveSine size={isMobile ? 24 : 32} stroke={1.5} />
                                <Title order={1} style={{ 
                                    fontSize: isMobile ? '2rem' : '3rem', 
                                    fontWeight: 900, 
                                    color: '#1a3a59', 
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                    textAlign: 'center'
                                }}>
                                    Welcome!
                                </Title>
                            </Group>

                            <Grid gutter={isMobile ? 40 : 80} align="center" justify="center">
                                <Grid.Col span={isMobile ? 12 : 6}>
                                    <Image
                                        radius="md"
                                        h={isMobile ? 200 : 300}
                                        w="auto"
                                        fit="contain"
                                        src="form.svg"
                                        sx={{ filter: 'drop-shadow(3px 3px 6px rgba(0,0,0,0.3))' }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={isMobile ? 9 : 6} align="center" justify="center">
                                    {user && (
                                        <Box
                                            sx={(theme) => ({
                                                backgroundColor: theme.fn.rgba(theme.white, 0.9),
                                                borderRadius: theme.radius.lg,
                                                padding: theme.spacing.md,
                                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                                width: '100%',
                                                maxWidth: isMobile ? '100%' : 400,
                                                
                                            })}
                                        >
                                            <Stack spacing="sm">
                                                <Group>
                                                    <ThemeIcon color="blue" size={isMobile ? "md" : "lg"} radius="md">
                                                        <IconUser size={isMobile ? 16 : 20} />
                                                    </ThemeIcon>
                                                    <Title order={3} weight={700} c="blue.9" size={isMobile ? "h4" : "h3"}>{user.fullName}</Title>
                                                </Group>
                                                <Group>
                                                    <ThemeIcon color="cyan" size={isMobile ? "md" : "lg"} radius="md">
                                                        <IconMail size={isMobile ? 16 : 20} />
                                                    </ThemeIcon>
                                                    <Text size={isMobile ? "sm" : "md"} c="gray.7">{user.primaryEmailAddress?.emailAddress}</Text>
                                                </Group>
                                            </Stack>
                                        </Box>
                                    )}
                                </Grid.Col>
                            </Grid>

                            <Divider my="sm" w="90%" variant="dashed" color="gray.3" />

                            <Group position="center" spacing="md">
                                <ThemeIcon size={isMobile ? "lg" : "xl"} radius="md" variant="light" color="teal">
                                    <IconClipboardList size={isMobile ? 32 : 42} stroke={2} />
                                </ThemeIcon>
                                <ButtonTo 
                                    buttonText="Go to Forms" 
                                    routeString="Forms" 
                                    variant="gradient" 
                                    gradient={{ from: 'teal', to: 'lime', deg: 105 }}
                                    size={isMobile ? "md" : "lg"}
                                    radius="md"
                                    preProcess={() => { }} 
                                    postProcess={() => { }} 
                                />
                            </Group>
                        </Stack>
                    </Paper>
                )}
            </Transition>
        </Container>
    );
}

export default Welcome;